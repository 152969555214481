import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';
// 查询token主列表
export const tokenList = (id) => {
	return request({
		url: pvhApiBase + "Cashier/GetTokenByUserId",
		method: "get",
		params: {
			userId: id
		}
	})
}
// 生成token
export const addToken = (TokenId, TokenName, ExpireTime, GrantUsers, GrantRoles, GrantPermission, GrantPermissionList) => {
	return request({
		url: pvhApiBase + "Cashier/PostToken",
		method: "post",
		data: {
			TokenId, TokenName, ExpireTime, GrantUsers, GrantRoles, GrantPermission, GrantPermissionList
		}
	})
}
// 删除token
export const delToken = (tokenId) => {
	return request({
		url: pvhApiBase + "Cashier/DeleteToken",
		method: "delete",
		params: {
			tokenId
		}
	})
}
// 获取用户权限
export const userJurisdiction = (id) => {
	return request({
		url: pvhApiBase + "Cashier/GetApiPermissionByUserId",
		method: "get",
		params: {
			userId: id
		}
	})
}